import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TodoList.css";

// Создаем функциональный компонент TodoLists
const TodoLists = () => {
  // Создаем состояние для списка задач на работе
  const [workTodos, setWorkTodos] = useState([]);
  // Создаем состояние для списка домашних задач
  const [homeTodos, setHomeTodos] = useState([]);

  // Создаем состояние для текущей задачи, которую вводит пользователь в поле "Работа"
  const [currentWorkTodo, setCurrentWorkTodo] = useState("");
  // Создаем состояние для текущей задачи, которую вводит пользователь в поле "Домашние дела"
  const [currentHomeTodo, setCurrentHomeTodo] = useState("");

  // Создаем состояние для хранения текущей темы (светлая или тёмная)
  const [theme, setTheme] = useState("light");

  // Создаем состояние для хранения текущего времени
  const [currentTime, setCurrentTime] = useState(new Date());

  // Используем хук useEffect для загрузки задач с сервера при монтировании компонента
  useEffect(() => {
    // Асинхронная функция для получения списка рабочих задач
    const fetchWorkTodos = async () => {
      try {
        // Отправляем GET-запрос на сервер
        const response = await axios.get(
          "http://194.61.52.29:5111/api/todo/work-todos"
        );
        // Обновляем состояние workTodos данными, полученными от сервера
        setWorkTodos(response.data);
      } catch (error) {
        // Выводим ошибку в консоль, если запрос не удался
        console.error("Error fetching work todos:", error);
      }
    };

    // Асинхронная функция для получения списка домашних задач
    const fetchHomeTodos = async () => {
      try {
        // Отправляем GET-запрос на сервер
        const response = await axios.get(
          "http://194.61.52.29:5111/api/todo/home-todos"
        );
        // Обновляем состояние homeTodos данными, полученными от сервера
        setHomeTodos(response.data);
      } catch (error) {
        // Выводим ошибку в консоль, если запрос не удался
        console.error("Error fetching home todos:", error);
      }
    };

    // Вызываем функции для загрузки задач
    fetchWorkTodos();
    fetchHomeTodos();
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только один раз при монтировании компонента

  // Второй useEffect для обновления времени каждую секунду
  useEffect(() => {
    // Устанавливаем интервал, который будет обновлять состояние currentTime каждую секунду
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Интервал в 1000 миллисекунд (1 секунда)

    // Возвращаем функцию очистки, которая отменяет интервал при размонтировании компонента
    return () => clearInterval(interval);
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только один раз при монтировании компонента

  // Функция для переключения темы
  const toggleTheme = () => {
    // Если текущая тема светлая, переключаем на тёмную, и наоборот
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Асинхронная функция для добавления новой задачи
  const addTodo = async (list) => {
    // Определяем, какую задачу мы добавляем (рабочую или домашнюю)
    const currentTodo = list === "work" ? currentWorkTodo : currentHomeTodo;
    // Выбираем функцию для обновления состояния задач (рабочих или домашних)
    const setTodos = list === "work" ? setWorkTodos : setHomeTodos;

    // Проверяем, что задача не пустая
    if (currentTodo.trim() !== "") {
      try {
        // Отправляем POST-запрос на сервер для добавления новой задачи
        const response = await axios.post(
          `http://194.61.52.29:5111/api/todo/${list}-todos/add`,
          {
            text: currentTodo, // Текст задачи
            completed: false, // Начальное состояние задачи (не выполнена)
          }
        );
        // Обновляем состояние задач, добавляя новую задачу
        setTodos((prevTodos) => [...prevTodos, response.data]);
        // Очищаем текстовое поле после добавления задачи
        if (list === "work") {
          setCurrentWorkTodo("");
        } else {
          setCurrentHomeTodo("");
        }
      } catch (error) {
        // Выводим ошибку в консоль, если запрос не удался
        console.error("Error adding todo:", error);
      }
    }
  };

  // Функция для обработки нажатия клавиши "Enter"
  const handleKeyDown = (event, list) => {
    // Если пользователь нажал "Enter", вызываем функцию addTodo
    if (event.key === "Enter") {
      addTodo(list);
    }
  };

  // Асинхронная функция для удаления задачи
  const DelTodo = async (id, list) => {
    // Выбираем функцию для обновления состояния задач (рабочих или домашних)
    const setTodos = list === "work" ? setWorkTodos : setHomeTodos;
    try {
      // Отправляем DELETE-запрос на сервер для удаления задачи
      await axios.delete(
        `http://194.61.52.29:5111/api/todo/${list}-todos/delete/${id}`
      );
      // Обновляем состояние задач, удаляя задачу с указанным id
      setTodos((prevTodos) => prevTodos.filter((todo) => todo._id !== id));
    } catch (error) {
      // Выводим ошибку в консоль, если запрос не удался
      console.error("Error deleting todo:", error);
    }
  };

  // Асинхронная функция для переключения состояния задачи (выполнена/не выполнена)
  const toggleTodo = async (id, list) => {
    // Выбираем соответствующий массив задач (рабочих или домашних)
    const todos = list === "work" ? workTodos : homeTodos;
    // Выбираем функцию для обновления состояния задач (рабочих или домашних)
    const setTodos = list === "work" ? setWorkTodos : setHomeTodos;
    // Ищем задачу по её id
    const todo = todos.find((todo) => todo._id === id);

    // Если задача не найдена, выводим ошибку и прекращаем выполнение функции
    if (!todo) {
      console.error(`Todo with id ${id} not found`);
      return;
    }

    try {
      // Отправляем PATCH-запрос на сервер для переключения состояния задачи
      const response = await axios.patch(
        `http://194.61.52.29:5111/api/todo/${list}-todos/toggle/${id}`,
        {
          completed: !todo.completed, // Переключаем состояние задачи
        }
      );

      // Если сервер вернул данные, обновляем состояние задач
      if (response.data) {
        setTodos((prevTodos) =>
          prevTodos.map((todo) => (todo._id === id ? response.data : todo))
        );
      } else {
        // Если сервер вернул пустые данные, выводим ошибку
        console.error("Empty response data");
      }
    } catch (error) {
      // Выводим ошибку в консоль, если запрос не удался
      console.error("Error toggling todo:", error);
    }
  };

  // Возвращаем JSX-разметку, описывающую компонент
  return (
    <div className="container">
      <div className={`todo ${theme}`}>
        {/* Заголовок с кнопкой для переключения темы и отображением текущего времени */}
        <div className="header">
          <div className="time">
            {/* Отображаем текущее время, дату и день недели */}
            <div>{currentTime.toLocaleTimeString()}</div>
            {currentTime.toLocaleDateString()}&nbsp;
            {" / "}&nbsp;
            {currentTime.toLocaleDateString("ru-RU", { weekday: "long" })}
          </div>
          <div className="theme-toggle" onClick={toggleTheme}>
            <div className={`icon ${theme === "light" ? "moon" : "sun"}`}></div>
          </div>
        </div>

        {/* Блок для задач, связанных с работой */}
        <div className="todo_work">
          <h2 className="title_work">Робота</h2>
          <div className="wrapper">
            <div className="inp">
              {/* Текстовое поле для ввода новой задачи */}
              <input
                type="text"
                value={currentWorkTodo}
                onChange={(event) => setCurrentWorkTodo(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event, "work")}
              />
              {/* Кнопка для добавления задачи */}
              <div className="btn_add" onClick={() => addTodo("work")}>
                add
              </div>
            </div>
            {/* Список задач */}
            <ul className="tasks">
              {workTodos.map((elem) => (
                <div className={`task_left ${theme}`} key={elem._id}>
                  <li className="task">
                    {/* Чекбокс для переключения состояния задачи */}
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={elem.completed}
                      onChange={() => toggleTodo(elem._id, "work")}
                    />
                    {elem.text}
                  </li>
                  {/* Кнопка для удаления задачи */}
                  <div
                    className="del"
                    onClick={() => DelTodo(elem._id, "work")}
                  ></div>
                </div>
              ))}
            </ul>
          </div>
        </div>

        {/* Блок для задач, связанных с домашними делами */}
        <div className="todo_home">
          <h2 className="title_home">Домашні справи</h2>
          <div className="wrapper">
            <div className="inp">
              {/* Текстовое поле для ввода новой задачи */}
              <input
                type="text"
                value={currentHomeTodo}
                onChange={(event) => setCurrentHomeTodo(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event, "home")}
              />
              {/* Кнопка для добавления задачи */}
              <div className="btn_add" onClick={() => addTodo("home")}>
                add
              </div>
            </div>
            {/* Список задач */}
            <ul className="tasks">
              {homeTodos.map((elem) => (
                <div className={`task_left ${theme}`} key={elem._id}>
                  <li className="task">
                    {/* Чекбокс для переключения состояния задачи */}
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={elem.completed}
                      onChange={() => toggleTodo(elem._id, "home")}
                    />
                    {elem.text}
                  </li>
                  {/* Кнопка для удаления задачи */}
                  <div
                    className="del"
                    onClick={() => DelTodo(elem._id, "home")}
                  ></div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

// Экспортируем компонент, чтобы его можно было использовать в других частях приложения
export default TodoLists;
